var AvaamoChatBot=function(t){
    function o(t,o)
    {
        var n=document.createElement("script");
        n.setAttribute("src",t);
        n.onload=o;
        document.body.appendChild(n);
    }
    return this.options=t||{},
    this.load=function(t)
    {
        o(this.options.url,
            function() {
                window.Avaamo.addFrame();
                t&&"function"==typeof(t)&&t(window.Avaamo)
            }
        )
    },this
};

function loadChat(channelId, botToken, testParam) {
    var chatBox;
    let gtmId = sessionStorage.getItem('gtmId');
    if(botToken){
         chatBox = new AvaamoChatBot({url: `https://c0.avaamo.com/web_channels/${channelId}?user_info=${botToken}&custom_properties[dyn8x8_chat_source_identifier]=studentportal&custom_properties[dyn8x8_gaPropertyId]=${gtmId}`});
    } else {
        chatBox = new AvaamoChatBot({url: `https://c0.avaamo.com/web_channels/${channelId}`});
    }

    chatBox.load(function(avaamo){
      var AgentTerminatedChatMessage = "Your chat has ended. If you have additional questions, Charlotte Bot is here to help.";
      var switchBackToBotMessage = "I am still available to respond to your queries";
      var endByUserMessage = "You have ended the live chat session.";
      var sessionExpiredMessage = "Oops! You may need to refresh your browser or log back in to the student portal before I can help you with that";
      var disambiguationMessage = "I’m sorry. I wasn’t able to understand your question at this time, Give me minute while I search Walden’s latest resources to find an answer that suits you the best.";
      avaamo.onChatIframeLoad = function(){
        var popup = document.querySelector('#avaamo__popup');
        var botClose = document.querySelector('.avaamo_popup__close');
        var btn = document.createElement("button");

        btn.innerHTML = "End Live Chat";
        btn.id = "live-agent-end";
        btn.classList.add("end-live-chat");
        btn.classList.add("hide");
        popup.appendChild(btn);

        btn.addEventListener('click', function(){
          window.Avaamo.sendMessage(endByUserMessage, "#end agent")
          });
      }
      avaamo.onBotMessage = function(message){
        if (message.content.includes("Thank you for contacting") && message.content.includes("How can I help you?")) {
          var btn = document.querySelector('#live-agent-end');
          btn.classList.remove("hide");
        }

        if(message.content.includes(AgentTerminatedChatMessage) || message.content.includes(endByUserMessage) || message.content.includes(switchBackToBotMessage)){
          var btn = document.querySelector('#live-agent-end');
          btn.classList.add("hide");
        }

        if(message.content.includes(sessionExpiredMessage)){
          let messageCreated = new Date( message.created_at *1000);
          messageCreated = new Date(messageCreated.getTime() + 10000);
          const currentTime = new Date();
          if(messageCreated > currentTime) {
            console.log("User session expired in charlotte bot, please re-login");
            const event = new CustomEvent('chatBotSessionExpired');
            document.dispatchEvent(event);
          }
        }

        if(message.content.includes(disambiguationMessage)){
          let messageCreated = new Date( message.created_at *1000);
          messageCreated = new Date(messageCreated.getTime() + 10000);
          const currentTime = new Date();
          if(messageCreated > currentTime) {
            console.log("None of these selected from disambiguation, call Knowledge Search flow");
            window.Avaamo.sendMessage("Search Again");
          }
        }
      }
    });
}
